<template>
  <div>
    <a-button
      v-if="showBtn"
      style="float: right;margin-right: 10px;margin-bottom: 10px;z-index: 9;"
      type="primary"
      @click="visible = true"
      :disabled="disabled"
      >添加</a-button
    >
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
              {{ index + 1 }}
          </template>
      </a-table-column>
      <a-table-column title="单位名称" data-index="objectName"></a-table-column>
      <a-table-column title="评估人" data-index="evaluatorName"></a-table-column>
      <a-table-column v-if="showBtn" title="操作" align="center" width="100px">
        <template slot-scope="text, record, index">
          <a
            v-if="index < list.length"
            href="#"
            :class="disabled ? '' : 'danger'"
            @click.prevent="remove(index)"
            :disabled="disabled"
            >删除</a
          >
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      width="800px"
      :visible="visible"
      title="新增单位"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit" width="640px">
        <a-form-item label="单位名称">
          <a-select v-decorator="[
            'deptId',
            {
              rules: [
                { required: true, message: '请输入后选择！' },
              ],
            },
          ]" placeholder="请选择单位">
            <a-select-option
              v-for="item in organizationList"
              :key="item.id"
              :value="item.id"
              >{{ item.uniqueName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="评估人">
          <div>
            <EmployeeSelector
              @change="(arr) => {
                if (arr.length) {
                  selectedUser = arr[0];
                } else {
                  selectedUser = {};
                }
              }
                "
              :value="selectedUser.name ? [selectedUser] : []"
            >
              <a-button block style="text-align: left">
                <div v-if="selectedUser.name">{{ selectedUser.name }}</div>
                <div v-else>&nbsp;</div>
              </a-button>
            </EmployeeSelector>
          </div>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";
import request from "@/api/request";

function queryCompanyOrg() {
  return request({
    url: "/user-service/org/queryCompanyOrg"
  });
}
export default {
  components: {
    EmployeeSelector
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      organizationList: [],
      selectedUser: {},
    };
  },

  mounted() {
    queryCompanyOrg().then(res => {
      this.organizationList = res
    });
  }, 

  methods: {
    cancel() {
      this.visible = false;
      this.form.resetFields();
      this.selectedUser = {};
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.selectedUser.userId) {
            this.$message.error("请选择评估人");
            return;
          }
          this.list.push({
            objectId: values.deptId,
            objectName: this.organizationList.filter(v=>v.id == values.deptId)[0].uniqueName,
            evaluatorId: this.selectedUser.userId,
            evaluatorName: this.selectedUser.name,
          });
          this.cancel();
        }
      });
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #999;
  span {
    padding-left: 0.5em;
  }
}
</style>