var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showBtn)?_c('a-button',{staticStyle:{"float":"right","margin-right":"10px","margin-bottom":"10px","z-index":"9"},attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":function($event){_vm.visible = true}}},[_vm._v("添加")]):_vm._e(),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"序号","width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}}])}),_c('a-table-column',{attrs:{"title":"单位名称","data-index":"objectName"}}),_c('a-table-column',{attrs:{"title":"评估人","data-index":"evaluatorName"}}),(_vm.showBtn)?_c('a-table-column',{attrs:{"title":"操作","align":"center","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [(index < _vm.list.length)?_c('a',{class:_vm.disabled ? '' : 'danger',attrs:{"href":"#","disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")]):_vm._e()]}}],null,false,1443814591)}):_vm._e()],1),_c('a-modal',{attrs:{"width":"800px","visible":_vm.visible,"title":"新增单位","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false,"width":"640px"},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"单位名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'deptId',
          {
            rules: [
              { required: true, message: '请输入后选择！' },
            ],
          },
        ]),expression:"[\n          'deptId',\n          {\n            rules: [\n              { required: true, message: '请输入后选择！' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请选择单位"}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"评估人"}},[_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.selectedUser.name ? [_vm.selectedUser] : []},on:{"change":(arr) => {
              if (arr.length) {
                _vm.selectedUser = arr[0];
              } else {
                _vm.selectedUser = {};
              }
            }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedUser.name)?_c('div',[_vm._v(_vm._s(_vm.selectedUser.name))]):_c('div',[_vm._v(" ")])])],1)],1)]),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }