<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal title="选择项目" :visible="visible" @cancel="cancel" :footer="null" width="600px">
      <a-input-search
        style="margin-bottom: 16px"
        placeholder="请输入项目名称搜索"
        enter-button
        v-model="searchValue"
      ></a-input-search>

      <a-table bordered :data-source="filteredList" :loading="loading" rowKey="id">
        <a-table-column title="项目名称" data-index="name"></a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="javacript:void(0)" @click="select(text)">选择</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import request from "@/api/request";
function fetchList() {
  return request({
    url: "/office-service/quality/inspect/project/queryAllProject"
  });
}
export default {
  props: {
    inspectUnitId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      list: [],
      visible: false,
      loading: false,
      searchValue: ""
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    }
  },

  computed: {
    filteredList() {
      let list = this.list;
      if (this.inspectUnitId) {
        list = list.filter(
          item => item.productionUnitId === this.inspectUnitId
        );
      }

      if (this.searchValue) {
        return list.filter(
          item => item.name && item.name.indexOf(this.searchValue) > -1
        );
      } else {
        return list;
      }
    }
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList()
        .then(res => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.visible = false;
    },

    select(text) {
      this.$emit("change", text);
      this.cancel();
    }
  }
};
</script>